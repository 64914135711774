.no-bs-border,
.no-bs-border:focus,
.no-bs-border:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  font-size: medium !important;
}

.top-strip {
  height: 35px;
  width: 100%;
  background-color: black;

  padding-left: 25px;
}

.dropdown-item {
  color: #e1e1e1 !important;
}

.dropdown-toggle {
  font-size: medium;
}

/* .dropdown-item:hover {
  background-color: #121212 !important;
} */

.topnav-company-name {
  color: white;
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
  text-decoration: none !important;
  /* margin-left: 25px; */
}

/* !important for extra small screens  */
@media only screen and (max-width: 300px) {
  .topnav-company-name {
    font-size: small;
    margin-left: 65px !important;
  }
}

.topnav {
  overflow: hidden;
  background-color: black;
  ;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  position: fixed;
  top: 0;
  width: 100%;
  height: 89px;
  z-index: 4;
  text-transform: uppercase;
  color: rgb(63, 63, 63);
  font-family: "Raleway", sans-serif;
  text-decoration: none !important;
  transition: height 0.5s;
  transition: height 0.5s;
  /* transition: border-bottom-left-radius 0.5s;
  transition: border-bottom-right-radius 0.5s; */

  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.topnav-logo {
  width: 45px;
  height: auto;
  position: absolute;
  top: 10px;
  left: 20px;
}

.topnav-logo:hover {
  opacity: 50%;
}

.top-strip-number {
  color: black;
  text-decoration: none;
  /* color: rgb(63, 63, 63); */
  font-family: "Raleway", sans-serif;
  margin-right: 20px;
  /* text-transform: uppercase; */
  /* font-weight: bold; */
}

.top-strip-number a {
  color: white;
}

.top-strip-icon {
  padding: 4px;
  /* height: 100%; */
  margin-top: 5px;
  margin-left: 20px;
  margin-right: 20px;
  /* height: 25px; */
  color: white;
}

.top-strip-icon:hover {
  /* color: rgb(0, 153, 255); */
  color: #39a0ed;
}

.topnav-item {
  float: left;
  display: block;
  color: #1e1e1e;
  text-align: center;
  text-decoration: none !important;
  font-size: 17px;
  border-radius: 100px;
  padding: 2px 15px;
  margin-top: 13px;
  /* todo  */
  margin-right: 5px;
  margin-left: 5px;
}

.topnav-item:hover {
  /* background-color: #ddd; */
  /* font-weight: bold; */

  color: white;
}
.topnav-item.active {
  font-weight: bold;
  /* background-color: #1e88e5; */
  /* color: white; */
  /* box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); */
}

.topnav .icon {
  display: none;
}

.hamburger-icon {
  display: none !important;
}

.top-strip-number:hover {
  color: #39a0ed !important;
  text-decoration: none !important;
}

@media screen and (max-width: 600px) {
  .topnav-logo {
    width: 45px;
    height: auto;
    position: absolute;
    top: 10px;
    left: 20px;
  }
}

.mobile-nav-item {
  display: none;
}

@media screen and (max-width: 600px) {
  .topnav.responsive .mobile-nav-item {
    display: block;
    margin-top: 20px;
    font-size: 40px;
    margin-left: 45%;
    color: black;
  }
  .topnav-company-name {
    margin-left: 70px;
  }
  .topnav-item {
    /* border-radius: 45px; */
    padding: 12px 25px;
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
  }
  .top-nav-brand {
    padding-top: 2px !important;
  }
  .topnav-item.active {
    color: white;
    font-weight: bold;
    text-decoration: none !important;
    background-color: #0288d1;
  }
  .topnav.responsive {
    position: fixed;
    height: 260px;
    transition: height 0.5s;
    /* transition: border-bottom-left-radius 0.5s; */
    /* transition: border-bottom-right-radius 0.5s; */
    /* border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px; */
  }
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .hamburger-icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
    text-decoration: none !important;
  }

  .top-strip {
    display: none;
  }
  .mobile-nav-spacer {
    height: 25vh;
  }
  .topnav-item:not(:first-child) {
    display: none;
  }

  .topnav-item {
    float: right;

    display: block;
  }

  .topnav {
    height: 49px;
  }
  .topnav-first {
    display: block !important;
    float: left;
    margin-top: 12px;
    /* position: absolute;
    left: 20px !important; */
  }

  .hamburger-icon {
    float: right;
    display: block !important;
  }
}
