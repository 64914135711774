.bg-video-container {
  height: 65vh;
  width: 100%;
  overflow: hidden;
}


@media only screen and (min-width: 600px) {
  .bg-video {
    font-size: 50px;
    margin-top: -100px;
  
  }
}
